import { NavLink } from "react-router-dom"

const ReportGenWorkflowCard = () => {
    return (
        <div className="rounded-xl w-52 h-40 bg-gradient-to-br from-purple-500 to-orange-500 flex flex-col p-4 justify-between">
            <div className="text-white font-semibold text-sm"> 
                Report <br/> Generation 
                <p className="text-white text-xxs opacity-70 pt-1 Class
                    Properties
                    leading-4" > Automated by your digital analyst 
                </p>
            </div>
            <div>
                <p className="text-white text-xs opacity-70">Coming Soon...</p>
            </div>
        </div>
    )
}

export default ReportGenWorkflowCard

