import { useSelector } from "react-redux";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import PulseLoader from "react-spinners/PulseLoader";

import { RootState } from "../../app/store";
import styles from "./styles.module.scss";

const MessageItem = ({ id }: { id: string }) => {
    const message = useSelector((state: RootState) => state.message.messages.find((message) => message.id === id));
    const responseMessage = useSelector((state: RootState) => state.message.messages.find((responseMessage) => message?.id === responseMessage.response_to));

    if(!message || !responseMessage) return null;

    const isLoadingMessageType = responseMessage.id === 'loading';
    const processedText = responseMessage?.text;
    const content = isLoadingMessageType ? 
        <div className="pl-14">
            <PulseLoader 
                size="8"
                color="#27174B"
                speedMultiplier={1.2}
            />
        </div>
        :
        <div className="px-14 text-md w-full lg:prose-sm">
            <ReactMarkdown 
            className="w-full"
            remarkPlugins={[remarkGfm]} 
            rehypePlugins={[rehypeRaw]}
            >{processedText}</ReactMarkdown>
        </div>
       

    return (
        <div className={`flex flex-col mb-8 ${styles.messageWrapper} px-4 py-6 rounded-2xl mt-4 w-4/5`}>
            <div className="whitespace-pre-wrap"> 
                <p className="font-semibold text-lg">{message.text}</p>
            </div>
            <div className="mt-4">
                <div className="flex flex-row items-center">
                    <div className={`h-11 w-11 rounded-full bg-indigo-950`}></div>
                    <p className="pl-3 text-md font-medium">{responseMessage.sender}</p>
                </div>
                <div className="w-full">
                    {content}
                </div>
            </div>
        </div>
    );

    // Determine the alignment and background color based on the sender
    // const isUserMessage = message.sender === 'You';
    // const isLoadingMessageType = message.id === 'loading';
    // const senderBgClass = isUserMessage ? "bg-violet-800" : "bg-indigo-950";
    // const content = isLoadingMessageType ? 
    //     <div className="pl-12">
    //         <PulseLoader 
    //             size="8"
    //             color="#27174B"
    //             speedMultiplier={1.2}
    //         />
    //     </div>
    //     :
    //     <p className="pl-10 text-md">{responseMessage?.text}</p>;

    // return (
    //     <div className={`flex flex-col mb-8 ${styles.messageWrapper} p-10 rounded-2xl`}>
    //         <div className="flex flex-row items-center">
    //             <p className="pl-10 font-bold text-lg mb-10">{message.text}</p>
    //         </div>
    //         <div>
    //             {content}
    //         </div>
    //         {message.references && (
    //             <ul className="text-xs list-disc pl-5 text-gray-600">
    //                 {message.references.map((ref, index) => (
    //                     <li key={index}>{ref}</li>
    //                 ))}
    //             </ul>
    //         )}
    //     </div>
    // );
};

export default MessageItem;
